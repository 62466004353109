<script>
	import { page } from '$app/stores';

	import JsonLd from './JsonLd.svelte';
	import OpenGraph from './OpenGraph.svelte';
</script>

<svelte:head>
	<title
		>{($page?.data?.title ?? $page?.data?.h1 ?? 'Indow Windows') +
			' | Indow Window Inserts'}</title
	>
	<meta name="description" content={$page?.data?.description ?? ''} />
	{#if $page?.data?.noindex}
		<meta name="robots" content="noindex" />
	{/if}
</svelte:head>

<JsonLd />
<OpenGraph />
